import React from 'react';
import './contact-us-and-hours.css';

export interface ContactUsAndHoursProps {
  style?: React.CSSProperties;
}

export const ContactUsAndHours: React.FC<ContactUsAndHoursProps> = ({
  style
}) => {
  return (
    <div className="Contact" style={style}>
      <div className="Contact-wrapper">
        <h3 className="Contact-title">CONTACT US</h3>
        <span className="Contact-item">
          <a href="mailto:customercare@whistleexpress.com" style={{ color: 'white' }}>
            customercare@whistleexpress.com
          </a>
        </span>
        <span className="Contact-item">
          <a href="tel:980-246-4037" style={{ color: 'white' }}>
            (980) 246-4037
          </a>
          {/* <a href="tel:833-668-5930" style={{ color: 'white' }}>
            (833) 668-5930
          </a> */}
        </span>
        {/* <div className="Contact-item" style={{ color: 'white', marginTop: 16 }}>
          Disclaimer:
          <p style={{ fontSize: 12, lineHeight: 1.5, fontWeight: 500 }}>
            Changes to Fins Wash Club account, including cancellations, can be
            handled at your local Fins Car Wash where any team member will be
            happy to assist you.
          </p>
        </div> */}
      </div>
      <div>
        <div className="Contact-wrapper">
          <h3 className="Contact-title">HOURS</h3>
          <span className="Contact-item">8 a.m. - 8 p.m.</span>
          <span className="Contact-item">7 days a week.</span>
        </div>
      </div>
    </div>
  );
};

export default ContactUsAndHours;
